import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Image from 'gatsby-image';
import { rhythm } from '../../../utils/typography';

export const BlogFooter = ({ short }) => {
  const data = useStaticQuery(graphql`
    query BlogFooterQuery {
      avatar: file(absolutePath: { regex: "/profile-pic/" }) {
        childImageSharp {
          fixed(width: 60, height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            shortSummary
          }
          social {
            twitter
            dev
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata;

  return (
    <div>
      <p>
        <i>
          After graduation, my career is entirely centered around learning and improving as a developer. I’ve began
          working full time as a React developer and I’ll be blogging about everything that I encounter and learn during
          this journey. This will range from improving communicational skills in a technical environment, becoming a
          better developer, improving technical skills in React and JavaScript, and discussing career related topics. In
          all of my posts, the focus will be on my personal experiences, learnings, difficulties, solutions (if
          present), and also flaws.
        </i>
      </p>
      {short ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginBottom: 0 }}>
            Written by {author.name}, a {author.shortSummary}. You can find me on{' '}
            <a href={`https://twitter.com/${social.twitter}`}>Twitter</a> or check out{' '}
            <Link to={`/blog/`}>my other articles</Link>.
          </p>
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author.name}
            style={{
              marginLeft: rhythm(1 / 3),
              minWidth: 60,
              borderRadius: `100%`,
            }}
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        </div>
      ) : (
        <p style={{ marginBottom: 0 }}>
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author.name}
            style={{
              float: 'right',
              display: 'inline-block',
              marginLeft: rhythm(1 / 3),
              marginBottom: rhythm(1 / 3),
              minWidth: 60,
              borderRadius: `100%`,
            }}
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
          If you’re either interested in these topics, more personalised technical stories, or the perspective of a
          learning developer, you can follow me either on <a href={`https://twitter.com/${social.twitter}`}>Twitter</a>{' '}
          or at <a href={`https:/dev.to/${social.dev}`}>Dev</a> to stay up to date with my blogposts. I’m always
          learning, so stay tuned for more stories! 🎉
        </p>
      )}
    </div>
  );
};
