import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import { BlogFooter } from '../components/Layout/Footers/BlogFooter';
import { BlogWebsiteTag } from '../components/tags';
import { NewsletterSignupForm } from '../components/Newsletters';

const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        meta={
          post.frontmatter.cover_image
            ? [
                {
                  property: `og:image`,
                  content: post.frontmatter.cover_image,
                },
                {
                  property: `twitter:image`,
                  content: post.frontmatter.cover_image,
                },
              ]
            : []
        }
        link={
          post.frontmatter.canonical
            ? [
                {
                  rel: `canonical`,
                  href: post.frontmatter.canonical,
                },
              ]
            : []
        }
      />
      <article>
        <header>
          <h1
            style={{
              marginTop: rhythm(1),
              marginBottom: 0,
              color: 'var(--blue)',
            }}
          >
            {post.frontmatter.title}
          </h1>
          <p
            style={{
              ...scale(-1 / 5),
              display: `inline-block`,
              marginBottom: rhythm(1),
            }}
          >
            {post.frontmatter.date}
            {` `}-{` `}
            {post.timeToRead} minutes
            {post.frontmatter.canonical && (
              <Fragment>
                {` - Originally published `}
                <a target="_blank" href={post.frontmatter.canonical} rel="noreferrer">
                  elsewhere
                </a>
                .
              </Fragment>
            )}
          </p>
          {post.frontmatter.website_tags && post.frontmatter.website_tags.length > 0 && (
            <div className="blogs__blogheader__tags">
              {post.frontmatter.website_tags.map(tag => (
                <div className="blogs__blogheader__tags__tag" key={tag}>
                  <BlogWebsiteTag tag={tag} />
                </div>
              ))}
            </div>
          )}
        </header>
        <section id="blog-post" style={{ letterSpacing: 0.25 }} dangerouslySetInnerHTML={{ __html: post.html }} />
        {post.frontmatter.newsletters?.includes('Uncommon React') ? (
          <aside style={{ marginTop: rhythm(3.5) }}>
            <NewsletterSignupForm />
          </aside>
        ) : null}
        <hr
          style={{
            marginTop: rhythm(3.5),
          }}
        />
        <footer
          style={{
            marginTop: rhythm(2),
            marginBottom: rhythm(3),
          }}
        >
          <BlogFooter short={post.frontmatter.short} />
        </footer>
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        cover_image
        website_tags
        newsletters
        short
        canonical
      }
      timeToRead
    }
  }
`;
